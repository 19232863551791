// ManufacturingProductComponent.js

import React, { useEffect, useState } from "react";
import "./ManufacturingProductComponent.css";
import DownloadButton from "../../Common Components/DownloadButton";
import EnquiryButton from "../../Common Components/EnquiryButton";
import EnquiryPopup from "../../Common Components/EnquiryPopup";

const ManufacturingProductComponent = ({
  images,
  about,
  title,
  manufactiringFeature,
  downloadLink,
  hyperlinks = [],
}) => {
  const [imgId, setImgId] = useState(1);
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  useEffect(() => {
    const displayWidth = document.querySelector(
      ".manufactiring-img-showcase img:first-child"
    ).clientWidth;
    document.querySelector(
      ".manufactiring-img-showcase"
    ).style.transform = `translateX(${-(imgId - 1) * displayWidth}px)`;
  }, [imgId]);

  const handleImgClick = (id, event) => {
    event.preventDefault();
    setImgId(id);
  };

  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };

  return (
    <div className="manufactiring-component-div">
      <div className="manufactiring-card-wrapper">
        <div className="manufactiring-card-product">
          <div className="manufactiring-product-imgs">
            <div className="manufactiring-img-display">
              <div className="manufactiring-img-showcase">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Product ${index + 1}`}
                    style={{
                      width: "100%",
                      display: index + 1 === imgId ? "block" : "none",
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="manufactiring-img-select">
              {images.map((image, index) => (
                <div className="manufactiring-img-item" key={index}>
                  <button
                    className="manufactiring-borderbackground"
                    data-id={index + 1}
                    onClick={(event) => handleImgClick(index + 1, event)}
                  >
                    <img
                      src={image}
                      alt={`Product ${index + 1}`}
                      style={{ width: "100%" }}
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="manufactiring-product-content">
            <h2 className="manufactiring-product-title">{title}</h2>
            <p className="manufactiring-about-info">{about}</p>
            <br></br>
            <ul className="manufactiring-feature">{manufactiringFeature}</ul>
            <div className="casestudy-button-manufactiring">
              {hyperlinks.map((hyperlink, index) => (
                <a
                  key={index}
                  href={hyperlink.link}
                  target="_self"
                  rel="noopener noreferrer"
                  className="hyperlink-button-enterprise"
                >
                  {hyperlink.name}
                </a>
              ))}
            </div>
            <div className="manufactiring-component-button-container">
              <DownloadButton downloadLink={downloadLink} />
              <EnquiryButton onClick={toggleEnquiryPopup} />
            </div>
          </div>
        </div>
      </div>
      <EnquiryPopup isOpen={isEnquiryPopupOpen} onClose={toggleEnquiryPopup} />
    </div>
  );
};

export default ManufacturingProductComponent;
