// EnterprisePTPAndPtMPUBRCategoryPage.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EnterprisePTPAndPtMPUBRCategoryPage.css";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import heroprod from "../../../assets/images/PTP1.jpg";
import heroprod1 from "../../../assets/images/ptmpt.png";
import routersImage from "../../../assets/images/cambium_networks_logo.jpg";
import switchesImage from "../../../assets/images/radwinfinal.png";
import accessPointsImage from "../../../assets/images/mimosa.jpg";
import alto from "../../../assets/images/altai2.jpg";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EnterpriseSubCategory from "../../EntertrpriseComponents/EnterpriseSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterprisePTPAndPtMPUBRCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "Cambium",
      image: routersImage,
      path: "/EnterpriseCambiumSubcategoryPage",
      description: "",
    },
    {
      name: "Radwin",
      image: switchesImage,
      path: "/EnterpriseRadwinSubcategoryPage",
      description: "",
    },
    {
      name: "Mimosa",
      image: accessPointsImage,
      path: "/EnterpriseMimosaSubcategoryPage",
      description: "",
    },
    {
      name: "Altai",
      image: alto,
      path: "/EnterpriseAltaiSubcategoryPage",
      description: "",
    },
  ];

  return (
    <div className="enterprise-category">
      <Helmet>
        <title> PTP and PtMP UBR - Cambium Network Products, Radwin Products, Mimosa Networks Products, Altai Products - Dealer, Distributor, supplier in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Explore CalidTech's Enterprise PTP and PtMP UBR solutions featuring top brands like Altai, Mimosa, Radwin, and Cambium. We deliver high-performance wireless communication systems tailored to meet your business's connectivity needs" />
        <meta name="keywords" content="High-Performance Wireless Networks,Point-to-Multipoint Systems,Point-to-Point Connectivity,Wireless Communication Solutions,Cambium Networks UBR,Radwin PTP Systems,Mimosa Network Solutions,Altai Wireless Products,Enterprise UBR Solutions,PTP and PtMP Solutions, PTPA and PtMPUBR supplier in mumbai" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="PTPA and PtMPUBR" />
        <meta property="og:description" content="Explore CalidTech's Enterprise PTP and PtMP UBR solutions featuring top brands like Altai, Mimosa, Radwin, and Cambium. We deliver high-performance wireless communication systems tailored to meet your business's connectivity needs" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://calidtech.com/" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Calid Technologies" />
        <meta name="twitter:description" content="Explore CalidTech's Enterprise PTP and PtMP UBR solutions featuring top brands like Altai, Mimosa, Radwin, and Cambium. We deliver high-performance wireless communication systems tailored to meet your business's connectivity needs" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="PTPA and PtMPUBR" />
        <meta itemprop="description" content="Explore CalidTech's Enterprise PTP and PtMP UBR solutions featuring top brands like Altai, Mimosa, Radwin, and Cambium. We deliver high-performance wireless communication systems tailored to meet your business's connectivity needs" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterprisePTPAndPtMPUBRCategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        PTP and PTMP UBR
      </div>
      <div className="container-enterprise mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="Slide 1"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>

        <h1 className="enterprise-category-heading">
          <div class="main-heading-entire-site">PTP and PTMP UBR</div>
        </h1>
        <p className="enterprise-category-information">
          {" "}
          Our Point-to-Point (PTP) and Point-to-Multipoint (PTMP) UBR solutions are expertly designed to deliver scalable, high-performance wireless connectivity for a wide range of applications. As an authorized dealer and supplier across India, including cities like Mumbai, Pune, Delhi, Bengaluru, Hyderabad, and Chennai, we provide reliable solutions for extending broadband access to multiple sites, deploying comprehensive surveillance systems, and establishing wide-area networks. Our PTP and PTMP solutions offer the flexibility, reliability, and connectivity needed to support your evolving network infrastructure needs.
        </p>
        <h2 className="enterprise-subcategory-heading">
          <div class="main-heading-entire-site">Subcategories</div>
        </h2>
        <EnterpriseSubCategory subcategories={subcategories} />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterprisePTPAndPtMPUBRCategoryPage;
