/* ManufacturingMoxaSubcategoryPage.js */

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingMoxaSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import MGate5119 from "../../../assets/pdf/MGate-5119-Series.pdf";
import MGate5121 from "../../../assets/pdf/MGate-5121-Series.pdf";
import MGate5102 from "../../../assets/pdf/MGate-5102-PBM-PN-Series.pdf";
import NPortP5150A from "../../../assets/pdf/NPort-P5150A-Series.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingMoxaSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Protocol converters",
  // };

  const productDataList1 = [
    // MGate 5119 Series
    {
      images: [
        require("../../../assets/productImages/moxa-mgate-5119-series-image-1.jpg"),
        require("../../../assets/productImages/moxa-mgate-5119-series-image-2.jpg"),
        require("../../../assets/productImages/moxa-mgate-5119-series-image-3.jpg"),
        require("../../../assets/productImages/moxa-mgate-5119-series-image-3.jpg"),
      ],
      title: "MGate 5119 Series",
      about: (
        <>
          <p>
            The MGate 5119 is an industrial Ethernet gateway with 2 Ethernet
            ports and 1 RS-232/422/485 serial port. To integrate Modbus, IEC
            60870-5-101, and IEC 60870-5-104 devices with an IEC 61850 MMS
            network.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of MGate 5119 Series inPune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact us to avail best
            prices and discount for MGate 5119 Series.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Supports IEC 61850 MMS server</li>
          <li>Supports IEC 60870-5-101 master (balanced/unbalanced)</li>
        </>
      ),
      downloadLink: MGate5119,
      hyperlinks: [],
    },

    // MGate 5121 Series
    {
      images: [
        require("../../../assets/productImages/moxa-mgate-5121-series-image-1.jpg"),
        require("../../../assets/productImages/moxa-mgate-5121-series-image-2.jpg"),
        require("../../../assets/productImages/moxa-mgate-5121-series-image-3.jpg"),
        require("../../../assets/productImages/moxa-mgate-5121-series-image-3.jpg"),
      ],
      title: "MGate 5121 Series",
      about: (
        <>
          <p>
            The MGate 5121 is an industrial Ethernet gateway for converting
            CANopen or J1939 to Modbus TCP network communications. To integrate
            existing CANopen onto a Modbus TCP network.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of MGate 5121 Series in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore. Contact us to avail best
            prices and discount for MGate 5121 Series.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Supports Modbus TCP server and SNMP agent</li>
          <li>Flexible deployment with Ethernet cascading and dual subnet</li>
        </>
      ),
      downloadLink: MGate5121,
      hyperlinks: [],
    },

    //MGate 5102-PBM-PN Series
    {
      images: [
        require("../../../assets/productImages/moxa-mgate-5102-pbm-pn-series-image-1.jpg"),
        require("../../../assets/productImages/moxa-mgate-5102-pbm-pn-series-image-2.jpg"),
        require("../../../assets/productImages/moxa-mgate-5102-pbm-pn-series-image-3.jpg"),
        require("../../../assets/productImages/moxa-mgate-5102-pbm-pn-series-image-3.jpg"),
      ],
      title: "MGate 5102-PBM-PN",
      about: (
        <>
          <p>
            The MGate 5102-PBM-PN gateway provides a communication portal
            between PROFIBUS devices (e.g., PROFIBUS PLC or drive) and the
            PROFINET host.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of MGate 5102-PBM-PN in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana. Contact us to avail best
            prices and discount for MGate 5102-PBM-PN.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Protocol conversion between PROFIBUS and PROFINET</li>
          <li>Supports PROFIBUS DP V1 master</li>
        </>
      ),
      downloadLink: MGate5102,
      hyperlinks: [],
    },

    //NPort P5150A Series
    {
      images: [
        require("../../../assets/productImages/moxa-nport-p5150a-series-image-1.jpg"),
        require("../../../assets/productImages/moxa-nport-p5150a-series-image-2.jpg"),
        require("../../../assets/productImages/moxa-nport-p5150a-series-image-3.jpg"),
        require("../../../assets/productImages/moxa-nport-p5150a-series-image-3.jpg"),
      ],
      title: "NPort P5150A Series",
      about: (
        <>
          <p>
            NPort® P5150A device servers are designed to make serial devices
            network-ready in an instant. It is a power device and is IEEE
            802.3af compliant.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of NPort P5150A Series in Ahmednagar, Taloja, Shedung, Rabale, Kolhapur, Bhavnagar, Surat, Ahmedabad, Rajkot, Anand, patratu, Jamshedpur, Bengaluru, Rourkela, Sambhalpur, Bhubeneshwar, Cuttak, Mumbai. Contact us to avail
            best prices and discount for NPort P5150A Series.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>IEEE 802.3af-compliant PoE power device equipment</li>
          <li>Surge protection for serial, Ethernet, and power</li>
        </>
      ),
      downloadLink: NPortP5150A,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="moxa-page">
      <Helmet>
        <title>Moxa - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Moxa Dealer in Pune" />
        <meta name="keywords" content="Best Moxa Dealer in Pune" />
        <meta name="keywords" content="Moxa Distributor in Pune" />

        <meta name="keywords" content="Moxa Dealer in Mumbai" />
        <meta name="keywords" content="Best Moxa Dealer in Mumbai" />
        <meta name="keywords" content="Moxa Distributor in Mumbai" />

        <meta name="keywords" content="Moxa Dealer in Maharashtra" />
        <meta name="keywords" content="Best Moxa Dealer in Maharashtra" />
        <meta name="keywords" content="Moxa Distributor in Maharashtra" />

        <meta name="keywords" content="Moxa Dealer in Vadodara" />
        <meta name="keywords" content="Best Moxa Dealer in Vadodara" />
        <meta name="keywords" content="Moxa Distributor in Vadodara" />

        <meta name="keywords" content="Moxa Dealer in Surat" />
        <meta name="keywords" content="Best Moxa Dealer in Surat" />
        <meta name="keywords" content="Moxa Distributor in Surat" />

        <meta name="keywords" content="Moxa Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Moxa Dealer in Ahmedabad" />
        <meta name="keywords" content="Moxa Distributor in Ahmedabad" />

        <meta name="keywords" content="Moxa Dealer in Indore" />
        <meta name="keywords" content="Best Moxa Dealer in Indore" />
        <meta name="keywords" content="Moxa Distributor in Indore" />

        <meta name="keywords" content="Moxa Dealer in Jaipur" />
        <meta name="keywords" content="Best Moxa Dealer in Jaipur" />
        <meta name="keywords" content="Moxa Distributor in Jaipur" />

        <meta name="keywords" content="Moxa Dealer in Noida" />
        <meta name="keywords" content="Best Moxa Dealer in Noida" />
        <meta name="keywords" content="Moxa Distributor in Noida" />

        <meta name="keywords" content="Moxa Dealer in Gurugram" />
        <meta name="keywords" content="Best Moxa Dealer in Gurugram" />
        <meta name="keywords" content="Moxa Distributor in Gurugram" />

        <meta name="keywords" content="Moxa Dealer in Delhi" />
        <meta name="keywords" content="Best Moxa Dealer in Delhi" />
        <meta name="keywords" content="Moxa Distributor in Delhi" />

        <meta name="keywords" content="Moxa Dealer in Kolkata" />
        <meta name="keywords" content="Best Moxa Dealer in Kolkata" />
        <meta name="keywords" content="Moxa Distributor in Kolkata" />

        <meta name="keywords" content="Moxa Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Moxa Dealer in Bhubaneswar" />
        <meta name="keywords" content="Moxa Distributor in Bhubaneswar" />

        <meta name="keywords" content="Moxa Dealer in Hyderabad" />
        <meta name="keywords" content="Best Moxa Dealer in Hyderabad" />
        <meta name="keywords" content="Moxa Distributor in Hyderabad" />

        <meta name="keywords" content="Moxa Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Moxa Dealer in Visakhapatnam" />
        <meta name="keywords" content="Moxa Distributor in Visakhapatnam" />

        <meta name="keywords" content="Moxa Dealer in Chennai" />
        <meta name="keywords" content="Best Moxa Dealer in Chennai" />
        <meta name="keywords" content="Moxa Distributor in Chennai" />

        <meta name="keywords" content="Moxa Dealer in Coimbatore" />
        <meta name="keywords" content="Best Moxa Dealer in Coimbatore" />
        <meta name="keywords" content="Moxa Distributor in Coimbatore" />

        <meta name="keywords" content="Moxa Dealer in Bengaluru" />
        <meta name="keywords" content="Best Moxa Dealer in Bengaluru" />
        <meta name="keywords" content="Moxa Distributor in Bengaluru" />

        <meta name="keywords" content="Moxa Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Moxa Dealer in Tamil Nadu" />
        <meta name="keywords" content="Moxa Distributor in Tamil Nadu" />

        <meta name="keywords" content="Moxa Dealer in Karnataka" />
        <meta name="keywords" content="Best Moxa Dealer in Karnataka" />
        <meta name="keywords" content="Moxa Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how CalidTech delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingMoxaSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Industrial Edge Connectivity - Moxa
      </div>
      <div className="moxa-subproduct-nav">
        <button
          className={`moxa-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-moxa-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Protocol converters
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="moxa-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-moxa-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-moxa" : ""}
                >
                  <button
                    className="moxa-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingMoxaSubcategoryPage;
