// EnterpriseHPEArubaSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseHPEArubaSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Aruba2930 from "../../../assets/pdf/Aruba-2930.pdf";
import Aruba3810 from "../../../assets/pdf/Aruba-3810.pdf";
import Aruba6000 from "../../../assets/pdf/Aruba-6000.pdf";
import Aruba6300 from "../../../assets/pdf/Aruba-6300.pdf";
import Aruba6400 from "../../../assets/pdf/Aruba-6400.pdf";
import AP505 from "../../../assets/pdf/AP505.pdf";
import AP515 from "../../../assets/pdf/AP515.pdf";
import AP535 from "../../../assets/pdf/AP535.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseHPEArubaSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Access Switches",
  //   2: "Core and Distribution Switch",
  //   3: "Aruba Wireless",                               
  // };

  const productDataList1 = [
    //Aruba 2930
    {
      images: [
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
        require("../../../assets/productImages/Aruba-2930-1.png"),
      ],
      title: "Aruba 2930",
      about:(
        <>
        <p> The HPE Aruba Networking 2930F Switch Series is designed for customers creating smart digital workplaces that are optimized for mobile users with an integrated wired and wireless approach.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba 2930 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Aruba 2930.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Simplicity built-in</li>
          <li>Security for users and IoT</li>
          <li>Unified cloud-based management</li>
        </>
      ),
      downloadLink: Aruba2930,
      hyperlinks: [],
    },

    //Aruba 3810
    {
      images: [
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
        require("../../../assets/productImages/Aruba-3810-1.png"),
      ],
      title: "Aruba 3810",
      about:(
        <>
        <p>The HPE Aruba Networking 3810 Switch series provides performance and resiliency for enterprises, SMBs, and branch office networks.HPE Aruba Networking ClearPass offers centralized security.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba 3810 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Aruba 3810.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Powerful Layer 3 Ethernet switching</li>
          <li>10-unit stacking</li>
          <li>Multi-gigabit Ethernet and PoE</li>
        </>
      ),
      downloadLink: Aruba3810,
      hyperlinks: [],
    },

    //Aruba 6000
    {
      images: [
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
        require("../../../assets/productImages/Aruba-6000-1.png"),
      ],
      title: "Aruba 6000",
      about:(
        <>
        <p>The HPE Aruba Networking CX 6000 Switch Series is morden family of entry level access switches ideal for branch offices, midsize businesses, and small ebterprise.</p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba 6000 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Aruba 6000.</p>
        </>
      ),
        enterpriseFeature: (
        <>
          <li>Enterprise-class Ethernet Layer 2 connectivity</li>
          <li>PoE and built-in uplinks</li>
          <li>Quiet and compact 12 port</li>
        </>
      ),
      downloadLink: Aruba6000,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //Aruba 6300
    {
      images: [
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
        require("../../../assets/productImages/Aruba-6300-1.png"),
      ],
      title: "Aruba 6300",
      about:(
        <>
        <p>The HPE Aruba Networking CX 6300 Switch Series is a modern, flexible, and intelligent family of stackable switches ideal for enterprise network access, aggregation, core, and data center top of rack deployments.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba 6300 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Aruba 6300.</p>        </>
      ),
      enterpriseFeature: (
        <>
          <li>Speed and power for users and IoT</li>
          <li>Scalable growth made simple</li>
          <li>Flexible management</li>
        </>
      ),
      downloadLink: Aruba6300,
      hyperlinks: [],
    },

    //Aruba 6400
    {
      images: [
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
        require("../../../assets/productImages/Aruba-6400-1.jpg"),
      ],
      title: "Aruba 6400",
      about:(
        <>
        <p>The HPE Aruba Networking CX 6400 Switch Series is a modern, flexible and intelligent family of modular switches ideal for access, aggregation and core in enterprise campus and data center deployments.

          </p>
          <br></br>
          <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba 6400 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Aruba 6400.</p>
          </>
      ),
      enterpriseFeature: (
        <>
          <li>Powerful modular Layer 3 switches with BGP, EVPN, VXLAN, VRF, and OSPF with robust security and QoS.</li>
          <li>Full density HPE Smart Rate (1/2.5/5/10GbE) multigigabit, 90W PoE and SFP+ modules.</li>
          <li>High-performance switching with up to 28Tbps with 11.4Bpps.</li>
        </>
      ),
      downloadLink: Aruba6400,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AP505
    {
      images: [
        require("../../../assets/productImages/AP505-1.png"),
        require("../../../assets/productImages/AP505-2.png"),
        require("../../../assets/productImages/AP505-1.png"),
        require("../../../assets/productImages/AP505-2.png"),
      ],
      title: "Aruba AP505",
      about:(
        <>
        <p>These affordable Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of mobile, IoT and mobility requirements.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of AP505 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for AP505.</p>
          
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Entry level Dual Radio 2x2 802.11ax Wi-Fi 6 AP provides up to 1.49 Gbps maximum combined data rate.
          </li>
          <li>Support for OFDMA for enhanced multiuser efficiency, and WPA3 and Enhanced Open for greater security.</li>
          <li>IoT-ready with a built-in [1] Bluetooth 5 and Zigbee support.</li>
        </>
      ),
      downloadLink: AP505,
      hyperlinks: [],
    },

    //AP515
    {
      images: [
        require("../../../assets/productImages/AP515-1.png"),
        require("../../../assets/productImages/AP515-2.png"),
        require("../../../assets/productImages/AP515-1.png"),
        require("../../../assets/productImages/AP515-2.png"),
      ],
      title: "AP515",
      about:(
        <>
        <p>HPE Aruba Networking Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of IoT and mobility requirements.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of AP515  in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for AP515.</p>
          
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>IoT-ready Bluetooth 5 and Zigbee support</li>
          <li>2.69 gigabit per second maximum real-world speed</li>
          <li>Wi-Fi 6 support for OFDMA, MU-MIMO, plus Client Match</li>
        </>
      ),
      downloadLink: AP515,
      hyperlinks: [],
    },

    //AP535
    {
      images: [
        require("../../../assets/productImages/AP535-1.png"),
        require("../../../assets/productImages/AP535-2.png"),
        require("../../../assets/productImages/AP535-1.png"),
        require("../../../assets/productImages/AP535-2.png"),
      ],
      title: "AP535",
      about:(
        <>
<p>HPE Aruba Networking Wi-Fi 6 access points provide high-performance connectivity for any organization experiencing growing numbers of IoT and mobility requirements.
</p>
<br></br>
<p>

       Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of AP535 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for AP535.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Very high Wi-Fi 6 (802.11ax) performance with dual radios 4x4:4 802.11ax Wi-Fi 6 AP provides up to 2.97 Gbps maximum combined data rate.</li>
          <li>Support for OFDMA for enhanced multiuser efficiency, and WPA3 and Enhanced Open for greater security.</li>
          <li> IoT-ready with a built-in [1] Bluetooth 5 and Zigbee support.</li>
        </>
      ),
      downloadLink: AP535,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="hPEAruba-page">
      <Helmet>
        <title>HPE/ARUBA - Dealer,supplier and reseller in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealer Tags */}
        <meta name="keywords" content="HPE/ARUBA Dealer in Pune" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Pune" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Pune" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Mumbai" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Mumbai" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Mumbai" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Maharashtra" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Maharashtra" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Maharashtra" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Vadodara" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Vadodara" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Vadodara" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Surat" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Surat" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Surat" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Ahmedabad" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Ahmedabad" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Ahmedabad" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Gujarat" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Gujarat" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Gujarat" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Indore" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Indore" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Indore" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Madhya Pradesh" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Madhya Pradesh" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Jaipur" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Jaipur" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Jaipur" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Rajasthan" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Rajasthan" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Rajasthan" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Noida" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Noida" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Noida" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Gurugram" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Gurugram" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Gurugram" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Delhi" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Delhi" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Delhi" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Kolkata" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Kolkata" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Kolkata" />

        <meta name="keywords" content="HPE/ARUBA Dealer in West Bengal" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in West Bengal" />
        <meta name="keywords" content="HPE/ARUBA Distributor in West Bengal" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Bhubaneswar" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Bhubaneswar" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Odisha" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Odisha" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Odisha" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Hyderabad" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Hyderabad" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Hyderabad" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Telangana" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Telangana" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Telangana" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Visakhapatnam" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Visakhapatnam" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Andhra Pradesh" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Andhra Pradesh" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Chennai" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Chennai" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Chennai" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Coimbatore" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Coimbatore" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Coimbatore" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Tamil Nadu" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Tamil Nadu" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Bengaluru" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Bengaluru" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Bengaluru" />

        <meta name="keywords" content="HPE/ARUBA Dealer in Karnataka" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer in Karnataka" />
        <meta name="keywords" content="HPE/ARUBA Distributor in Karnataka" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.calidtech.com/EnterpriseHPEArubaSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - HPE Aruba
      </div>
      <div className="hPEAruba-subproduct-nav">
        <button
          className={`hPEAruba-subproduct-nav-button ${currentSubproduct === 1 ? "active-hPEAruba-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Access Switches
        </button>
        <button
          className={`hPEAruba-subproduct-nav-button ${currentSubproduct === 2 ? "active-hPEAruba-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Core and Distribution Switch
        </button>
        <button
          className={`hPEAruba-subproduct-nav-button ${currentSubproduct === 3 ? "active-hPEAruba-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Aruba Wireless
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="hPEAruba-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-hPEAruba-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-hPEAruba" : ""}
                >
                  <button
                    className="hPEAruba-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseHPEArubaSubcategoryPage;
