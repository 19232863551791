// EnterpriseJuniperMistSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseJuniperMistSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EX230024T from "../../../assets/pdf/EX2300-24T.pdf";
import EX340024T from "../../../assets/pdf/EX3400-24T.pdf";
import EX430024T from "../../../assets/pdf/EX4300-24T.pdf";
import AP32 from "../../../assets/pdf/AP32.pdf";
import AP33 from "../../../assets/pdf/AP33.pdf";
import AP43 from "../../../assets/pdf/AP43.pdf";
import EX230024P from "../../../assets/pdf/EX-2300-24P.pdf";
import EX340024P from "../../../assets/pdf/EX-3400-24P.pdf";
import EX430032F24P from "../../../assets/pdf/EX4300-32F-24P.pdf";
import EX460040F from "../../../assets/pdf/EX4600-40F.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseJuniperMistSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Access Switches",
  //   2: "Core and Distribution Switch",
  //   3: "Mist WiFi",
  // };

  const productDataList1 = [
    //EX2300-24T
    {
      images: [
        require("../../../assets/productImages/Juniper-EX2300-24T-Front.jpg"),
        require("../../../assets/productImages/EX2300-24T-2.jpg"),
        require("../../../assets/productImages/EX2300-24T-1.jpg"),
        require("../../../assets/productImages/EX2300-24T-2.jpg"),
      ],
      title: "Juniper EX2300-24T",
      about: (
        <>
          <p>The Juniper Networks® EX2300 line of Ethernet switches offers a compact, high-performance solution for supporting today's converged network access deployments.</p>
          <p>It is designed to provide efficient network access and reliable performance for modern enterprise environments.</p>
          <br></br>
           <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX2300-24T in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount  for Juniper EX2300-24T.</p>
        
        </>
      ), enterpriseFeature: (
        <>
          <li>
          24 x 10/100/1000BASE-T Ethernet Ports: These ports support Gigabit Ethernet speeds, providing high throughput for standard network traffic.
          </li>
          <li>
          Switching Capacity: 56 Gbps (Gigabits per second)
          Forwarding Throughput: 41.7 Mpps
          </li>
          <li>2 x 10G SFP+ Uplinks: Provides two 10G SFP+ (small form-factor pluggable) uplink ports for</li>
        </>
      ),
      downloadLink: EX230024T,
      hyperlinks: [],
    },

    //EX2300-24P
    {
      images: [
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
      ],
      title: "Juniper EX2300-24P",
      about:(
        <>
        <p>The Juniper Networks EX2300 Ethernet Switch offers an economical, entry-level, standalone solution for access-layer deployments in branch and remote offices, as well as enterprise campus networks.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX2300-24P in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Delhi. Contact us to avail best prices and discount  for Juniper EX2300-24P.</p>
        </>

      ),
      enterpriseFeature: (
        <>
          <li>4 x 10/100/1000BASE-T Ethernet Ports: These ports support Gigabit Ethernet speeds, providing high throughput for standard network traffic.</li>
          <li>PoE+ (Power over Ethernet Plus): The switch supports IEEE 802.3at PoE+ standard, which can deliver up to 25.5 watts per port. This is ideal for powering devices such as IP phones, wireless access points (APs), and security cameras without needing separate power cables.</li>
          <li>2 x 10G SFP+ Uplinks: Provides two 10G SFP+ (small form-factor pluggable) uplink ports for</li>
        </>
      ),
      downloadLink: EX230024P,
      hyperlinks: [],
    },

    //EX3400-24P
    {
      images: [
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
      ],
      title: "Juniper EX3400-24P",
      about:(
        <>
        <p>Juniper Networks EX3400 Ethernet Switch delivers a high-performance, flexible, and cost-effective solution for today's most demanding converged data, voice, and video enterprise access environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX3400-24P in  Maharashtra, Delhi, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount  for Juniper EX3400-24P.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>4 x 10/100/1000BASE-T Ethernet Ports: These ports support Gigabit Ethernet speeds, providing high throughput for standard network traffic.</li>
          <li>PoE+ (Power over Ethernet Plus): The switch supports IEEE 802.3at PoE+ standard, which can deliver up to 25.5 watts per port. This is ideal for powering devices such as IP phones, wireless access points (APs), and security cameras without needing separate power cables.</li>
          <li>2 x 10G SFP+ Uplinks: Provides two 10G SFP+ (small form-factor pluggable) uplink ports for</li>
        </>
      ),
      downloadLink: EX340024P,
      hyperlinks: [],
    },

    //EX4300-24P
    {
      images: [
        require("../../../assets/productImages/ex4300-24poe-frontwtop.jpg"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
      ],
      title: " EX4300-24P",
      about:(
        <>
        <p> IThe EX4300 line of Ethernet switches delivers the performance, flexibility, and scale required for both campus and data center Gigabit Ethernet (GbE) access switch environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of EX4300-24P in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai ,Pune. Contact us to avail best prices and discount  for EX4300-24P.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Switching Capacity: 128 Gbps (Gigabits per second)</li>
          <li>Forwarding Throughput: 95.2 Mpps (Million packets per second)</li>
          <li>Stackable Design: The EX4300 supports Virtual Chassis, enabling up to 10 switches to be managed as one unit for enhanced scalability and flexibility.</li>
          <li>10G Uplink Ports: The EX4300-24P includes 4 x 10G SFP+ uplink ports for high-speed connections to other network devices.</li>
        </>
      ),
      downloadLink: EX430032F24P,
      hyperlinks: [],
    },

    //EX3400-24T
    {
      images: [
        require("../../../assets/productImages/EX3400-24T-1.jpg"),
        require("../../../assets/productImages/EX3400-24T-2.jpg"),
        require("../../../assets/productImages/EX3400-24T-1.jpg"),
        require("../../../assets/productImages/EX3400-24T-2.jpg"),
      ],
      title: "Juniper EX3400-24T",
      about:(
        <>
        <p>The Juniper Networks® EX3400 Ethernet Switch with Juniper Networks Virtual Chassis technology provides enterprises with the flexibility and ease of management.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX3400-24T in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount  for Juniper EX3400-24T.</p>
        </>
      ),
      enterpriseFeature: (
        <>
        <li>Switching Capacity: 128 Gbps (Gigabits per second)</li>
        <li>Forwarding Throughput: 95.2 Mpps (Million packets per second)</li>
        <li>Stackable Design: The EX4300 supports Virtual Chassis, enabling up to 10 switches to be managed as one unit for enhanced scalability and flexibility.</li>
        <li>10G Uplink Ports: The EX4300-24P includes 4 x 10G SFP+ uplink ports for high-speed connections to other network devices.</li>
      </>
      ),
      downloadLink: EX340024T,
      hyperlinks: [],
    },

    //EX4300-24T
    {
      images: [
        require("../../../assets/productImages/EX4300-24T-1.jpg"),
        require("../../../assets/productImages/EX4300-24T-2.jpg"),
        require("../../../assets/productImages/EX4300-24T-1.jpg"),
        require("../../../assets/productImages/EX4300-24T-2.jpg"),
      ],
      title: "Juniper EX4300-24T",
      about:(
        <>
        <p>The Juniper Networks® EX4300 line of Ethernet switches with Virtual Chassis technology combines the carrier-class reliability of modular systems with the economics and flexibility of stackable platforms.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX4300-24T in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper EX4300-24T.</p>
        </>
      ),
      enterpriseFeature: (
        <>
        <li>Switching Capacity: 128 Gbps (Gigabits per second)</li>
        <li>Forwarding Throughput: 95.2 Mpps (Million packets per second)</li>
        <li>Stackable Design: The EX4300 supports Virtual Chassis, enabling up to 10 switches to be managed as one unit for enhanced scalability and flexibility.</li>
        <li>10G Uplink Ports: The EX4300-24P includes 4 x 10G SFP+ uplink ports for high-speed connections to other network devices.</li>
      </>

      ),
      downloadLink: EX430024T,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //EX4300-32F
    {
      images: [
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
      ],
      title: "Juniper EX4300-32F",
      about:(
        <>
        <p>The Juniper EX4300 line of Ethernet switches delivers the performance, flexibility, and scale required for both campus and data center Gigabit Ethernet (GbE) access switch environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper EX4300-32F in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper EX4300-32F.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
        
        <li>32-port 100/1000BASE-X SFP, 4x10GBASE-X SFP+, 2x40GBASE-X
       QSFP+, and 350 W AC PS (optics sold separately)
        </li>
          <li>Provides advanced Layer 3 features such as OSPF, BGP, RIP, VRRP, and more, making it suitable for routing across larger enterprise networks.</li>
        <li>Packet Switching Capacities: 232 Gbps (unidirectional)/464 Gbps (bidirectional)</li>
        </>
      ),
      downloadLink: EX430032F24P,
      hyperlinks: [],
    },

    //EX4600-40F
    {
      images: [
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
      ],
      title: "Juniper EX4600-40F",
      about:(
        <>
        <p>The EX4600 offers fixed 10 Gpbs and modular 10 Gpbs and 40 Gbps ports, giving it the flexibility to also be implemented in data center top-of-rack and service provider aggregation deployments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of EX4600-40F in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper EX4600-40F.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>EX4600 offers fixed 10 Gpbs and modular 10 Gpbs and 40 Gbps ports, giving it the flexibility to also be implemented in data center top-of-rack and service provider aggregation deployments.</li>
          <li>EX4600 base switch provides 24 fixed 1GbE SFP/10GbE SFP+ ports1 and 4 fixed 40GbE QSFP+ ports, providing the flexibility to support mixed 1GbE, 10GbE and 40GbE environments</li>
          <li>Switching capacity: 720 Gbps (unidirectional)/1.44 Tbps (bidirectional)</li>
        </>
      ),
      downloadLink: EX460040F,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AP32
    {
      images: [
        require("../../../assets/productImages/AP32-1.png"),
        require("../../../assets/productImages/AP32-2.png"),
        require("../../../assets/productImages/AP32-1.png"),
        require("../../../assets/productImages/AP32-2.png"),
      ],
      title: "Juniper AP32",
      about:(
        <>
        <p> The Juniper AP32 access point integrates Mist AI for AX capabilities to automate network operation and boost Wi-Fi performance, delivering an aggregate data rate up to 3.0 Gbps concurrently.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper AP32 in  Tamil Nadu, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper AP32.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>802.11ax (Wi-Fi 6); 5GHz: 4x4:4; 2.4GHz: 2x2:2</li>
          <li>The AP32 Series access points support 4x4:4 spatial streams in the 5GHz band for a maximum data rate of 2,400 Mbps and 2x2:2 spatial streams in the 2.4 GHz band for a maximum data rate of 575 Mbps.</li>
          <li>Antenna Options: Internal, External</li>
        </>
      ),
      downloadLink: AP32,
      hyperlinks: [],
    },

    //AP33
    {
      images: [
        require("../../../assets/productImages/AP33-1.png"),
        require("../../../assets/productImages/AP33-2.png"),
        require("../../../assets/productImages/AP33-1.png"),
        require("../../../assets/productImages/AP33-2.png"),
      ],
      title: "Juniper AP33",
      about:(
        <>
        <p>The Juniper AP33 access point integrates Mist AI for AX capabilities and a dynamic virtual Bluetooth® LE (vBLE) antenna array to automate network operation and boost Wi-Fi performance.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper AP33 in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper AP33.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>802.11ax (Wi-Fi 6); 5GHz: 4x4:4; 2.4GHz: 2x2:2</li>
          <li>aggregate data rate up to 3 Gbps with the 2.4 GHz and 5 GHz bands running concurrently.</li>
          <li>Antenna Options: Internal</li>
        </>
      ),
      downloadLink: AP33,
      hyperlinks: [],
    },

    //AP43
    {
      images: [
        require("../../../assets/productImages/AP43-1.png"),
        require("../../../assets/productImages/AP43-2.png"),
        require("../../../assets/productImages/AP43-1.png"),
        require("../../../assets/productImages/AP43-2.png"),
      ],
      title: "Juniper AP43",
      about:(
        <>
        <p>The AP43 Series works in conjunction with the Juniper Mist Cloud Architecture driven by Mist AI to collect and analyze metadata in near real-time from all wireless clients.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Juniper AP43 in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Juniper AP43.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>802.11ax (Wi-Fi 6); 5GHz: 4x4:4; 2.4GHz: 4x4:4</li>
          <li>Access points support tri-radio 4x4 802.11ax with maximum data rates of 2400 Mbps in the 5GHz band and 1148 Mbps in the 2.4GHz band.</li>
          <li>Third radio functions as a network, location, and security sensor, a synthetic test client radio, as well as a spectrum monitor.</li>
        </>
      ),
      downloadLink: AP43,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="juniperMist-page">
      <Helmet>
        <title>Juniper Mist - Dealer in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealers Tags */}
        <meta name="keywords" content="Juniper Mist Dealer in Pune" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Pune" />
        <meta name="keywords" content="Juniper Mist Distributor in Pune" />

        <meta name="keywords" content="Juniper Mist Dealer in Mumbai" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Mumbai" />
        <meta name="keywords" content="Juniper Mist Distributor in Mumbai" />

        <meta name="keywords" content="Juniper Mist Dealer in Maharashtra" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Maharashtra" />
        <meta name="keywords" content="Juniper Mist Distributor in Maharashtra" />

        <meta name="keywords" content="Juniper Mist Dealer in Vadodara" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Vadodara" />
        <meta name="keywords" content="Juniper Mist Distributor in Vadodara" />

        <meta name="keywords" content="Juniper Mist Dealer in Surat" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Surat" />
        <meta name="keywords" content="Juniper Mist Distributor in Surat" />

        <meta name="keywords" content="Juniper Mist Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Ahmedabad" />
        <meta name="keywords" content="Juniper Mist Distributor in Ahmedabad" />

        <meta name="keywords" content="Juniper Mist Dealer in Gujarat" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Gujarat" />
        <meta name="keywords" content="Juniper Mist Distributor in Gujarat" />

        <meta name="keywords" content="Juniper Mist Dealer in Indore" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Indore" />
        <meta name="keywords" content="Juniper Mist Distributor in Indore" />

        <meta name="keywords" content="Juniper Mist Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Juniper Mist Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Juniper Mist Dealer in Jaipur" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Jaipur" />
        <meta name="keywords" content="Juniper Mist Distributor in Jaipur" />

        <meta name="keywords" content="Juniper Mist Dealer in Rajasthan" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Rajasthan" />
        <meta name="keywords" content="Juniper Mist Distributor in Rajasthan" />

        <meta name="keywords" content="Juniper Mist Dealer in Noida" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Noida" />
        <meta name="keywords" content="Juniper Mist Distributor in Noida" />

        <meta name="keywords" content="Juniper Mist Dealer in Gurugram" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Gurugram" />
        <meta name="keywords" content="Juniper Mist Distributor in Gurugram" />

        <meta name="keywords" content="Juniper Mist Dealer in Delhi" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Delhi" />
        <meta name="keywords" content="Juniper Mist Distributor in Delhi" />

        <meta name="keywords" content="Juniper Mist Dealer in Kolkata" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Kolkata" />
        <meta name="keywords" content="Juniper Mist Distributor in Kolkata" />

        <meta name="keywords" content="Juniper Mist Dealer in West Bengal" />
        <meta name="keywords" content="Best Juniper Mist Dealer in West Bengal" />
        <meta name="keywords" content="Juniper Mist Distributor in West Bengal" />

        <meta name="keywords" content="Juniper Mist Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Bhubaneswar" />
        <meta name="keywords" content="Juniper Mist Distributor in Bhubaneswar" />

        <meta name="keywords" content="Juniper Mist Dealer in Odisha" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Odisha" />
        <meta name="keywords" content="Juniper Mist Distributor in Odisha" />

        <meta name="keywords" content="Juniper Mist Dealer in Hyderabad" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Hyderabad" />
        <meta name="keywords" content="Juniper Mist Distributor in Hyderabad" />

        <meta name="keywords" content="Juniper Mist Dealer in Telangana" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Telangana" />
        <meta name="keywords" content="Juniper Mist Distributor in Telangana" />

        <meta name="keywords" content="Juniper Mist Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Visakhapatnam" />
        <meta name="keywords" content="Juniper Mist Distributor in Visakhapatnam" />

        <meta name="keywords" content="Juniper Mist Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Juniper Mist Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Juniper Mist Dealer in Chennai" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Chennai" />
        <meta name="keywords" content="Juniper Mist Distributor in Chennai" />

        <meta name="keywords" content="Juniper Mist Dealer in Coimbatore" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Coimbatore" />
        <meta name="keywords" content="Juniper Mist Distributor in Coimbatore" />

        <meta name="keywords" content="Juniper Mist Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Tamil Nadu" />
        <meta name="keywords" content="Juniper Mist Distributor in Tamil Nadu" />

        <meta name="keywords" content="Juniper Mist Dealer in Bengaluru" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Bengaluru" />
        <meta name="keywords" content="Juniper Mist Distributor in Bengaluru" />

        <meta name="keywords" content="Juniper Mist Dealer in Karnataka" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Karnataka" />
        <meta name="keywords" content="Juniper Mist Distributor in Karnataka" />






        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseJuniperMistSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - Juniper Mist
      </div>
      <div className="juniperMist-subproduct-nav">
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 1 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Access Switches
        </button>
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 2 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Core and Distribution Switch
        </button>
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 3 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Mist WiFi
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="juniperMist-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-juniperMist-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-juniperMist" : ""}
                >
                  <button
                    className="juniperMist-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseJuniperMistSubcategoryPage;
