import React, { useState, useEffect } from "react";
import "./EnterpriseHeader.css";
import { NavLink } from "react-router-dom";
import logowithoutbg from "../../assets/images/logo_bgremove.png";

const EnterpriseHeader = ({ siteName }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isTop = scrollTop < 100;
      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`small-header ${isScrolled ? "scrolled" : ""}`}>
        <marquee className="blink">
          <p>Enterprise Solutions</p>
        </marquee>
      </div>
      <nav className={`custom-header-unique ${isScrolled ? "scrolled" : ""}`}>
        <input type="checkbox" id="nav-toggle-unique" />
        <div className="logo-unique">
          <NavLink to="/EnterpriseLandingPage">
            <img className="header-logo-withoutbg" src={logowithoutbg} alt="Logo" />
          </NavLink>
        </div>

        <div className="site-banner">{siteName}</div>
        <ul className="links-unique">
          <li>
            <NavLink
              className="a-link"
              to="/EnterpriseLandingPage"
              activeClassName="active"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className="a-link"
              to="/EnterpriseProductPage"
              activeClassName="active"
            >
              Products
            </NavLink>
          </li>
          <li>
            <NavLink
              className="a-link"
              to="/EnterpriceServicePage"
              activeClassName="active"
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              className="a-link"
              to="/EnterpriseCaseStudyPage"
              activeClassName="active"
            >
              Case Studies
            </NavLink>
          </li>
          <li>
            <NavLink
              className="a-link"
              to="/JobBoard"
              activeClassName="active"
            >
              Job Openings
            </NavLink>
          </li>
          <li>
            <NavLink
              className="a-link"
              to="/EnterpriseContactUs"
              activeClassName="active"
            >
              Contact
            </NavLink>
          </li>
        </ul>

        <label htmlFor="nav-toggle-unique" className="icon-burger-unique">
          <div className="line-unique"></div>
          <div className="line-unique"></div>
          <div className="line-unique"></div>
        </label>
      </nav>
    </div>
  );
};

export default EnterpriseHeader;
