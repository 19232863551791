// // ManufacturingServicePageNavBar.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import { useNavigate } from "react-router-dom";
import "./ManufacturingServicePageNavBar.css";

const ManufacturingServicePageNavBar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  // const [activeSubcategory, setActiveSubcategory] = useState(null);

  const categories = [
    { name: "Warehouse Management" },
    { name: "Wireless Planning" },
    { name: "IoT" },
    { name: "" },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCategoryClick = (categoryName) => {
    if (activeCategory !== categoryName) {
      setActiveCategory(categoryName);
      // setActiveSubcategory(null); // Reset active subcategory when category changes
      switch (categoryName) {
        case "Warehouse Management":
          navigate("/ManufacturingWarehouseManagementSubservicePage");
          break;
        case "Wireless Planning":
          navigate("/ManufacturingWirelessPlanningSubservicePage");
          break;
        case "IoT":
          navigate("/ManufacturingDevOpsSubservicePage");
          break;
        // case 'OT Security':
        //   navigate('/ManufacturingOTSecurityCategoryPage');
        //   break;
        // Add more cases for other categories if needed
        default:
          break;
      }
    }
  };

  // const handleSubcategoryClick = (subcategoryName) => {
  //   if (activeSubcategory !== subcategoryName) {
  //     setActiveSubcategory(subcategoryName);
  //     switch (subcategoryName) {
  //       case 'Hilscher':
  //         navigate('/ManufacturingHilscherSubcategoryPage');
  //         break;
  //       case 'Anybus':
  //         navigate('/ManufacturingAnybusSubcategoryPage');
  //         break;
  //       case 'Moxa':
  //         navigate('/ManufacturingMoxaSubcategoryPage');
  //         break;
  //       case 'Teltonika':
  //         navigate('/ManufacturingTeltonikaSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Ewon':
  //         navigate('/ManufacturingEwonSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //         break;
  //       case 'Dalnex':
  //         navigate('/ManufacturingDalnexSubcategoryPage');
  //       break;
  //       case 'P A':
  //         navigate('/ManufacturingPASubcategoryPage');
  //         break;
  //       case 'FortiGate':
  //         navigate('/ManufacturingFortiGateSubcategoryPage');
  //         break;
  //       case 'FortiGate':
  //         navigate('/ManufacturingFortiGateSubcategoryPage');
  //         break;
  //       // Add cases for other subcategories if needed
  //       default:
  //         break;
  //     }
  //   }
  // };

  return (
    <nav
      id="manufacturing-service-navbar"
      className={isMobileMenuOpen ? "active-manufacturing-service" : ""}
    >
      <Helmet>
        <title>CalidTech Factory Digital Transformation Services - Helmholz, Anybus, Dalnex, Teltonika Dealer Distributor in Pune Mumbai Maharashtra India</title>

        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com" />
      </Helmet>

      <button className="mobile-menu-icon-service" onClick={toggleMobileMenu}>
        ☰
      </button>
      <ul
        className={`manufacturing-nav-categories-service ${isMobileMenuOpen ? "mobile-menu" : ""
          }`}
      >
        {categories.map((category) => (
          <li
            key={category.name}
            className={`manufacturing-li-navbar ${activeCategory === category.name
              ? "active-manufacturing-service"
              : ""
              }`}
          >
            <button
              className="manufacturing-button-navbar-service manufacturing-navbar-category-name"
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.name}
            </button>
            {/* <ul className="manufacturing-subcategories-navbar">
              {category.subcategories.map((subcategory, index) => (
                <li key={index}>
                  <button className="manufacturing-subcategory-name-navbar" onClick={() => handleSubcategoryClick(subcategory)}>
                    {subcategory}
                  </button>
                </li>
              ))}
            </ul> */}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ManufacturingServicePageNavBar;
