// EnterpriseNetworkAndCybersecurityCategoryPage.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EnterpriseNetworkAndCybersecurityCategoryPage.css";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import heroprod from "../../../assets/images/cyber.jpg";
import heroprod1 from "../../../assets/images/cyber2.jpg";
import routersImage from "../../../assets/images/palo1.webp";
import switchesImage from "../../../assets/images/fortig.jpg";
import accessPointsImage from "../../../assets/images/sophos.png";
import accessPointsImage1 from "../../../assets/images/captive.jpg";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EnterpriseSubCategory from "../../EntertrpriseComponents/EnterpriseSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseNetworkAndCybersecurityCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "Palo Alto",
      image: routersImage,
      path: "/EnterprisePaloAltoSubcategoryPage",
      description: "",
    },
    {
      name: "Fortigate",
      image: switchesImage,
      path: "/EnterpriseFortiGateSubcategoryPage",
      description: "",
    },
    {
      name: "Sophos",
      image: accessPointsImage,
      path: "/EnterpriseSophosSubcategoryPage",
      description: "",
    },
    {
      name: "Captive Portal server",
      image: accessPointsImage1,
      path: "/EnterpriseAAAHotspotCaptivePortalserverSubcategoryPage",
      description: "",
    },
  ];

  return (
    <div className="enterprise-category">
      <Helmet>
        <title>Network and Cybersecurity - Palo Alto Firewall, FortiGate, FortiGate Firewall, Sophos Firewall, Captive Portal Server Products Dealer, Distributorand supplier in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Enhance your enterprise's network and cybersecurity with CalidTech's solutions featuring Captive Portal Server, Sophos, Fortigate, and Palo Alto. Our comprehensive services provide robust protection, optimized performance, and advanced security for your business infrastructure." />
        <meta name="keywords" content="Advanced Network Security Solutions,Business Network Protection,Cybersecurity Products,Network Security Services,Palo Alto Networks,Fortigate Firewall,Sophos Security,Captive Portal Server,Cybersecurity Solutions,Palo Alto,Fortigate,Sophos,Enterprise Network Solutions,Captive Portal server,Network and cybersecurity provider in Mumbai,Network and cybersecurity provider in Pune " />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Network and Cybersecurity" />
        <meta property="og:description" content="Enhance your enterprise's network and cybersecurity with CalidTech's solutions featuring Captive Portal Server, Sophos, Fortigate, and Palo Alto. Our comprehensive services provide robust protection, optimized performance, and advanced security for your business infrastructure." />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://calidtech.com/" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Network and Cybersecurity" />
        <meta name="twitter:description" content="Enhance your enterprise's network and cybersecurity with CalidTech's solutions featuring Captive Portal Server, Sophos, Fortigate, and Palo Alto. Our comprehensive services provide robust protection, optimized performance, and advanced security for your business infrastructure." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Network and Cybersecurity" />
        <meta itemprop="description" content="Enhance your enterprise's network and cybersecurity with CalidTech's solutions featuring Captive Portal Server, Sophos, Fortigate, and Palo Alto. Our comprehensive services provide robust protection, optimized performance, and advanced security for your business infrastructure." />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technolgies" />
        <meta name="application-name" content="Calid Technolgies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseNetworkAndCybersecurityCategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity
      </div>
      <div className="container-enterprise mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="Slide 1"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>

        <h1 className="enterprise-category-heading">
          <div class="main-heading-entire-site">Network and Cybersecurity</div>
        </h1>
        <p className="enterprise-category-information">
        Welcome to Calid Technologies, your trusted partner in network and cybersecurity solutions. As an authorized dealer and supplier across India, including key cities like Mumbai, Pune, Delhi, Bengaluru, Hyderabad, and Chennai, we specialize in delivering top-of-the-line products and services to safeguard your digital environment. Our solutions are designed to ensure your business operates smoothly and securely, providing robust protection and peace of mind for your network infrastructure.
        </p>
        <h2 className="enterprise-subcategory-heading">Subcategories</h2>
        <EnterpriseSubCategory subcategories={subcategories} />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseNetworkAndCybersecurityCategoryPage;
