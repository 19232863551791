import React from "react";
import Edelweiss from "../../EntertrpriseComponents/EnterpriseCaseStudyComponents/Edelweiss";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../../Common Components/Whatsapp";
import { Helmet } from 'react-helmet';


function EdelweissCaseStudyPage() {
  return (
    <div>
      <Helmet>
        <title>Calidtech - Edelweiss CaseStudy - HPE/Aruba, Palo Alto, Dell, Lenovo, Radmin, Altai Dealers Distributors in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Discover how Calidtech designed a cutting-edge data center and greenfield IT network setup for Edelweiss Financial. Explore the comprehensive case study on our expertise in advanced network solutions" />
        <meta name="keywords" content="Edelweiss Case study page,Edelweiss Finance Group,IT Network Setup, Data Center,palo alto" />
        <meta name="author" content="Calid Technolgies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Edelweiss CaseStudy Page" />
        <meta property="og:description" content="Explore our case study on Edelweiss Financial. Discover how we designed their Data Center and greenfield IT network setup, enhancing efficiency and performance." />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Edelweiss CaseStudy Page" />
        <meta name="twitter:description" content="Explore our case study on Edelweiss Financial. Discover how we designed their Data Center and greenfield IT network setup, enhancing efficiency and performance." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Edelweiss CaseStudy Page" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calidtech" />
        <meta name="application-name" content="Calidtech" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EdelweissCaseStudyPage" />
      </Helmet>
      <EnterpriseHeader />
      <Edelweiss />
      <EnterpriseHeader />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default EdelweissCaseStudyPage;
