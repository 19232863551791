// EnterpriseRuckusSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseRuckusSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import ICX7150 from "../../../assets/pdf/ICX7150.pdf";
import ICX7450 from "../../../assets/pdf/ICX7450.pdf";
import ICX7750 from "../../../assets/pdf/ICX7750.pdf";
import ICX7850 from "../../../assets/pdf/ICX7850.pdf";
import AP550 from "../../../assets/pdf/AP550.pdf";
import AP650 from "../../../assets/pdf/AP650.pdf";
import AP750 from "../../../assets/pdf/AP750.pdf";
import ICX7550 from "../../../assets/pdf/ICX7550.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseRuckusSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Access Switches",
  //   2: "Core and Distribution Switch",
  //   3: "Ruckus Wireless",
  // };

  const productDataList1 = [
    //ICX7150
    {
      images: [
        require("../../../assets/productImages/ICX7150-1.png"),
        require("../../../assets/productImages/ICX7150-2.png"),
        require("../../../assets/productImages/ICX7150-1.png"),
        require("../../../assets/productImages/ICX7150-2.png"),
      ],
      title: "RUCKUS ICX7150",
      about:(
        <>
        <p>The RUCKUS® ICX® 7150 series of stackable switches delivers the performance, flexibility, and scalability required for enterprise access deployment.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS ICX7150 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS ICX7150.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>PoE/PoE+/PoH to power 802.11ac Wi-Fi products</li>
          <li>sFlow-based network monitoring</li>
          <li>SDN-enabled with OpenFlow support</li>
        </>
      ),
      downloadLink: ICX7150,
      hyperlinks: [],
    },

    //ICX7450
    {
      images: [
        require("../../../assets/productImages/ICX7450-1.png"),
        require("../../../assets/productImages/ICX7450-2.png"),
        require("../../../assets/productImages/ICX7450-3.jpg"),
        require("../../../assets/productImages/ICX7450-4.jpg"),
      ],
      title: "RUCKUS ICX7450",
      about:(
        <>
        <p>The RUCKUS ICX 7450 delivers unprecedented scale-out density with enterprise-class availability. With SDN support, a service module for IPsec VPN, and 40 GbE ports for uplinks all in a stackable design.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS ICX7450 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS ICX7450.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Up to 3 uplink modules with 4 x 1 GbE, 4 x 10 GbE, or 1 x 40 GbE
            ports
          </li>
          <li>10 Gbps throughput per service module</li>
          <li>Up to 12 switches per stack</li>
        </>
      ),
      downloadLink: ICX7450,
      hyperlinks: [],
    },

    //ICX7750
    {
      images: [
        require("../../../assets/productImages/ICX7750-1.png"),
        require("../../../assets/productImages/ICX7750-2.png"),
        require("../../../assets/productImages/ICX7750-3.jpg"),
        require("../../../assets/productImages/ICX7750-1.png"),
      ],
      title: "RUCKUS ICX7750",
      about:(
        <>
        <p>The Ruckus® ICX® 7750 switch delivers industry-leading 10/40 GbE port density, advanced high-availability capabilities, and flexible stacking architecture.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS ICX7750 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS ICX7750.</p>
       
      </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Industry-leading 10/40 Gbps Ethernet port density and flexibility.
          </li>
          <li>Up to 32x40 GbE or 96x10 GbE ports per unit.</li>
          <li>Increased Reliability and Availability Through Redundancy.</li>
        </>
      ),
      downloadLink: ICX7750,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //ICX7850
    {
      images: [
        require("../../../assets/productImages/ICX7850-1.png"),
        require("../../../assets/productImages/ICX7850-2.png"),
        require("../../../assets/productImages/ICX7850-3.jpg"),
        require("../../../assets/productImages/ICX7850-4.jpg"),
      ],
      title: "RUCKUS ICX7850",
      about:(
        <>
        <p>Superior Scalability: Up 76.8 Tbps of combined switching capacity and 384x 100GbE or 576x 10/25 GbE ports per 12 unit's stack supports the most demanding enterprise environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS ICX7850 in Delhi, Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS ICX7850.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Industry-leading 40/100 Gbps Ethernet port density and flexibility.
          </li>
          <li>Instantaneous hitless failover.</li>
          <li>Flexible distributed chassis stacking architecture.</li>
        </>
      ),
      downloadLink: ICX7850,
      hyperlinks: [],
    },

    //ICX7550
    {
      images: [
        require("../../../assets/productImages/ICX7550-1.png"),
        require("../../../assets/productImages/ICX7550-2.png"),
        require("../../../assets/productImages/ICX7550-3.png"),
        require("../../../assets/productImages/ICX7550-4.png"),
      ],
      title: "RUCKUS ICX7550",
      about:(
        <>
        <p>The CommScope RUCKUS® ICX 7550 switch delivers the premium performance and scalability required for Wi-Fi 6 deployments and beyond with up to 48 ports of multigigabit connectivity.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS ICX7550 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS ICX7550.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Optimized for Wi-Fi 6 deployments and beyond.</li>
          <li>Power next generation PoE devices.</li>
          <li>Advanced L3 routing delivers design flexibility.</li>
        </>
      ),
      downloadLink: ICX7550,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AP550
    {
      images: [
        require("../../../assets/productImages/AP550-1.png"),
        require("../../../assets/productImages/AP550-2.png"),
        require("../../../assets/productImages/AP550-1.png"),
        require("../../../assets/productImages/AP550-2.png"),
      ],
      title: "RUCKUS R550",
      about:(
        <>
        <p>The RUCKUS® R550 access point (AP) with the latest Wi-Fi 6 (802.11 ax) technology delivers the ideal combination of increased capacity, improved coverage and affordability in dense environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS R550 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS R550.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Wi-Fi 6 2x2:2 Indoor Access Point with 1.8 Gbps max rate</li>
          <li>Supports four spatial streams (2x2:2 in 2.4GHz/5GHz). The R550 supports peak data rates of up to 1774 Mbps and efficiently manages up to 512 clients connections.</li>
          <li>Automate optimal throughput.</li>
        </>
      ),
      downloadLink: AP550,
      hyperlinks: [],
    },

    //AP650
    {
      images: [
        require("../../../assets/productImages/AP650-1.png"),
        require("../../../assets/productImages/AP650-2.png"),
        require("../../../assets/productImages/AP650-1.png"),
        require("../../../assets/productImages/AP650-2.png"),
      ],
      title: "RUCKUS R650",
      about:(
        <>
        <p>The R650 access point (AP) with the latest Wi-Fi 6 (802.11 ax) technology delivers increased capacity, improved coverage and performance in dense environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS R650 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for  RUCKUS R650.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>R650  supports six spatial streams (4x4:4 in 5GHz, 2x2:2 in 2.4GHz). The R650 supports peak data rates of up to 2974 Mbps and efficiently manages up to 512 clients connections</li>
          <li>Mitigate interference and extend coverage with patented BeamFlex+™ adaptive antenna technology utilizing several directional antenna patterns</li>
          <li>Multiple management options.</li>
        </>
      ),
      downloadLink: AP650,
      hyperlinks: [
        { link: "KarkinosCaseStudyPage", name: "Karkinos Case Study" },
      ],
    },

    //AP750
    {
      images: [
        require("../../../assets/productImages/AP750-1.jpg"),
        require("../../../assets/productImages/AP750-2.jpg"),
        require("../../../assets/productImages/AP750-1.jpg"),
        require("../../../assets/productImages/AP750-2.jpg"),
      ],
      title: " RUCKUS R750",
      about:(
        <>
        <p>The R750, with OFDMA and MU-MIMO capabilities, efficiently manages up to 1024 client connections with increased capacity, improved coverage and performance in ultra-high dense environments.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RUCKUS R750 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for RUCKUS R750.</p>
       
        </>
      ),
      enterpriseFeature: (
        <>
          <li>RUCKUS R750 is our high-end dual-band, dual-concurrent Wi-Fi 6 AP that supports 8 spatial streams (4x4:4 in 5GHz, 4x4:4 in 2.4GHz). </li>
          <li>The R750, with OFDMA and MU-MIMO capabilities, efficiently manages up to 1024 client connections with increased capacity, improved coverage and performance in ultra-high dense environments.</li>
          <li>Multigigabit access speeds.</li>
        </>
      ),
      downloadLink: AP750,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="ruckus-page">
      <Helmet>
        <title>Ruckus - Dealer in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Ruckus Dealer in Pune" />
        <meta name="keywords" content="Best Ruckus Dealer in Pune" />
        <meta name="keywords" content="Ruckus Distributor in Pune" />

        <meta name="keywords" content="Ruckus Dealer in Mumbai" />
        <meta name="keywords" content="Best Ruckus Dealer in Mumbai" />
        <meta name="keywords" content="Ruckus Distributor in Mumbai" />

        <meta name="keywords" content="Ruckus Dealer in Maharashtra" />
        <meta name="keywords" content="Best Ruckus Dealer in Maharashtra" />
        <meta name="keywords" content="Ruckus Distributor in Maharashtra" />

        <meta name="keywords" content="Ruckus Dealer in Vadodara" />
        <meta name="keywords" content="Best Ruckus Dealer in Vadodara" />
        <meta name="keywords" content="Ruckus Distributor in Vadodara" />

        <meta name="keywords" content="Ruckus Dealer in Surat" />
        <meta name="keywords" content="Best Ruckus Dealer in Surat" />
        <meta name="keywords" content="Ruckus Distributor in Surat" />

        <meta name="keywords" content="Ruckus Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Ruckus Dealer in Ahmedabad" />
        <meta name="keywords" content="Ruckus Distributor in Ahmedabad" />

        <meta name="keywords" content="Ruckus Dealer in Gujarat" />
        <meta name="keywords" content="Best Ruckus Dealer in Gujarat" />
        <meta name="keywords" content="Ruckus Distributor in Gujarat" />

        <meta name="keywords" content="Ruckus Dealer in Indore" />
        <meta name="keywords" content="Best Ruckus Dealer in Indore" />
        <meta name="keywords" content="Ruckus Distributor in Indore" />

        <meta name="keywords" content="Ruckus Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Ruckus Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Ruckus Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Ruckus Dealer in Jaipur" />
        <meta name="keywords" content="Best Ruckus Dealer in Jaipur" />
        <meta name="keywords" content="Ruckus Distributor in Jaipur" />

        <meta name="keywords" content="Ruckus Dealer in Rajasthan" />
        <meta name="keywords" content="Best Ruckus Dealer in Rajasthan" />
        <meta name="keywords" content="Ruckus Distributor in Rajasthan" />

        <meta name="keywords" content="Ruckus Dealer in Noida" />
        <meta name="keywords" content="Best Ruckus Dealer in Noida" />
        <meta name="keywords" content="Ruckus Distributor in Noida" />

        <meta name="keywords" content="Ruckus Dealer in Gurugram" />
        <meta name="keywords" content="Best Ruckus Dealer in Gurugram" />
        <meta name="keywords" content="Ruckus Distributor in Gurugram" />

        <meta name="keywords" content="Ruckus Dealer in Delhi" />
        <meta name="keywords" content="Best Ruckus Dealer in Delhi" />
        <meta name="keywords" content="Ruckus Distributor in Delhi" />

        <meta name="keywords" content="Ruckus Dealer in Kolkata" />
        <meta name="keywords" content="Best Ruckus Dealer in Kolkata" />
        <meta name="keywords" content="Ruckus Distributor in Kolkata" />

        <meta name="keywords" content="Ruckus Dealer in West Bengal" />
        <meta name="keywords" content="Best Ruckus Dealer in West Bengal" />
        <meta name="keywords" content="Ruckus Distributor in West Bengal" />

        <meta name="keywords" content="Ruckus Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Ruckus Dealer in Bhubaneswar" />
        <meta name="keywords" content="Ruckus Distributor in Bhubaneswar" />

        <meta name="keywords" content="Ruckus Dealer in Odisha" />
        <meta name="keywords" content="Best Ruckus Dealer in Odisha" />
        <meta name="keywords" content="Ruckus Distributor in Odisha" />

        <meta name="keywords" content="Ruckus Dealer in Hyderabad" />
        <meta name="keywords" content="Best Ruckus Dealer in Hyderabad" />
        <meta name="keywords" content="Ruckus Distributor in Hyderabad" />

        <meta name="keywords" content="Ruckus Dealer in Telangana" />
        <meta name="keywords" content="Best Ruckus Dealer in Telangana" />
        <meta name="keywords" content="Ruckus Distributor in Telangana" />

        <meta name="keywords" content="Ruckus Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Ruckus Dealer in Visakhapatnam" />
        <meta name="keywords" content="Ruckus Distributor in Visakhapatnam" />

        <meta name="keywords" content="Ruckus Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Ruckus Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Ruckus Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Ruckus Dealer in Chennai" />
        <meta name="keywords" content="Best Ruckus Dealer in Chennai" />
        <meta name="keywords" content="Ruckus Distributor in Chennai" />

        <meta name="keywords" content="Ruckus Dealer in Coimbatore" />
        <meta name="keywords" content="Best Ruckus Dealer in Coimbatore" />
        <meta name="keywords" content="Ruckus Distributor in Coimbatore" />

        <meta name="keywords" content="Ruckus Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Ruckus Dealer in Tamil Nadu" />
        <meta name="keywords" content="Ruckus Distributor in Tamil Nadu" />

        <meta name="keywords" content="Ruckus Dealer in Bengaluru" />
        <meta name="keywords" content="Best Ruckus Dealer in Bengaluru" />
        <meta name="keywords" content="Ruckus Distributor in Bengaluru" />

        <meta name="keywords" content="Ruckus Dealer in Karnataka" />
        <meta name="keywords" content="Best Ruckus Dealer in Karnataka" />
        <meta name="keywords" content="Ruckus Distributor in Karnataka" />


        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseRuckusSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - Ruckus Networks
      </div>
      <div className="ruckus-subproduct-nav">
        <button
          className={`ruckus-subproduct-nav-button ${currentSubproduct === 1 ? "active-ruckus-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Access Switches
        </button>
        <button
          className={`ruckus-subproduct-nav-button ${currentSubproduct === 2 ? "active-ruckus-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Core and Distribution Switch
        </button>
        <button
          className={`ruckus-subproduct-nav-button ${currentSubproduct === 3 ? "active-ruckus-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Ruckus Wireless
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="ruckus-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="ruckus-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-ruckus" : ""}
                >
                  <button
                    className="ruckus-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseRuckusSubcategoryPage;
