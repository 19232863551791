// ManufacturingZscalerSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingZscalerSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Zscaler from "../../../assets/pdf/Zscaler.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingZscalerSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "ZTNA",
  // };

  const productDataList1 = [
    //Zscaler
    {
      images: [
        require("../../../assets/productImages/ZTNA-1.jpeg"),
        require("../../../assets/productImages/ZTNA-1.jpeg"),
        require("../../../assets/productImages/ZTNA-1.jpeg"),
        require("../../../assets/productImages/ZTNA-1.jpeg"),
      ],
      title: "Zscaler",
      about: (
        <>
          <p>
            Zscaler Privileged Remote Access enables fast, direct, and secure
            access to operational technology (OT) and industrial Internet of
            Thing assets from field locations, the factory floor or anywhere
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Zscaler in Maharashtra, Bhavnagar, MP,
            Bhiwadi, Gurugram, Uttarakhand, Patratu, Orissa, Bengaluru. Contact
            us to avail best prices and discount for Zscaler.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Delivers an exceptional user experience.</li>
          <li>Boosts uptime and productivity.</li>
        </>
      ),
      downloadLink: Zscaler,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="zscaler-page">
      <Helmet>
        <title>Zscaler - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Zscaler Dealer in Pune" />
        <meta name="keywords" content="Best Zscaler Dealer in Pune" />
        <meta name="keywords" content="Zscaler Distributor in Pune" />

        <meta name="keywords" content="Zscaler Dealer in Mumbai" />
        <meta name="keywords" content="Best Zscaler Dealer in Mumbai" />
        <meta name="keywords" content="Zscaler Distributor in Mumbai" />

        <meta name="keywords" content="Zscaler Dealer in Maharashtra" />
        <meta name="keywords" content="Best Zscaler Dealer in Maharashtra" />
        <meta name="keywords" content="Zscaler Distributor in Maharashtra" />

        <meta name="keywords" content="Zscaler Dealer in Vadodara" />
        <meta name="keywords" content="Best Zscaler Dealer in Vadodara" />
        <meta name="keywords" content="Zscaler Distributor in Vadodara" />

        <meta name="keywords" content="Zscaler Dealer in Surat" />
        <meta name="keywords" content="Best Zscaler Dealer in Surat" />
        <meta name="keywords" content="Zscaler Distributor in Surat" />

        <meta name="keywords" content="Zscaler Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Zscaler Dealer in Ahmedabad" />
        <meta name="keywords" content="Zscaler Distributor in Ahmedabad" />

        <meta name="keywords" content="Zscaler Dealer in Indore" />
        <meta name="keywords" content="Best Zscaler Dealer in Indore" />
        <meta name="keywords" content="Zscaler Distributor in Indore" />

        <meta name="keywords" content="Zscaler Dealer in Jaipur" />
        <meta name="keywords" content="Best Zscaler Dealer in Jaipur" />
        <meta name="keywords" content="Zscaler Distributor in Jaipur" />

        <meta name="keywords" content="Zscaler Dealer in Noida" />
        <meta name="keywords" content="Best Zscaler Dealer in Noida" />
        <meta name="keywords" content="Zscaler Distributor in Noida" />

        <meta name="keywords" content="Zscaler Dealer in Gurugram" />
        <meta name="keywords" content="Best Zscaler Dealer in Gurugram" />
        <meta name="keywords" content="Zscaler Distributor in Gurugram" />

        <meta name="keywords" content="Zscaler Dealer in Delhi" />
        <meta name="keywords" content="Best Zscaler Dealer in Delhi" />
        <meta name="keywords" content="Zscaler Distributor in Delhi" />

        <meta name="keywords" content="Zscaler Dealer in Kolkata" />
        <meta name="keywords" content="Best Zscaler Dealer in Kolkata" />
        <meta name="keywords" content="Zscaler Distributor in Kolkata" />

        <meta name="keywords" content="Zscaler Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Zscaler Dealer in Bhubaneswar" />
        <meta name="keywords" content="Zscaler Distributor in Bhubaneswar" />

        <meta name="keywords" content="Zscaler Dealer in Hyderabad" />
        <meta name="keywords" content="Best Zscaler Dealer in Hyderabad" />
        <meta name="keywords" content="Zscaler Distributor in Hyderabad" />

        <meta name="keywords" content="Zscaler Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Zscaler Dealer in Visakhapatnam" />
        <meta name="keywords" content="Zscaler Distributor in Visakhapatnam" />

        <meta name="keywords" content="Zscaler Dealer in Chennai" />
        <meta name="keywords" content="Best Zscaler Dealer in Chennai" />
        <meta name="keywords" content="Zscaler Distributor in Chennai" />

        <meta name="keywords" content="Zscaler Dealer in Coimbatore" />
        <meta name="keywords" content="Best Zscaler Dealer in Coimbatore" />
        <meta name="keywords" content="Zscaler Distributor in Coimbatore" />

        <meta name="keywords" content="Zscaler Dealer in Bengaluru" />
        <meta name="keywords" content="Best Zscaler Dealer in Bengaluru" />
        <meta name="keywords" content="Zscaler Distributor in Bengaluru" />

        <meta name="keywords" content="Zscaler Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Zscaler Dealer in Tamil Nadu" />
        <meta name="keywords" content="Zscaler Distributor in Tamil Nadu" />

        <meta name="keywords" content="Zscaler Dealer in Karnataka" />
        <meta name="keywords" content="Best Zscaler Dealer in Karnataka" />
        <meta name="keywords" content="Zscaler Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how CalidTech delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />
        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingZscalerSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - Zscaler
      </div>
      <div className="zscaler-subproduct-nav">
        <button
          className={`zscaler-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-zscaler-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          ZTNA
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="zscaler-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-zscaler-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-zscaler" : ""}
                >
                  <button
                    className="zscaler-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingZscalerSubcategoryPage;
