import React, { useState, useEffect } from "react";
import EnterpriseBackground from "../EntertrpriseComponents/EnterpriseBackground";
import EnterpriseHero from "../EntertrpriseComponents/EnterpriseHero";
import EnterpriseFeature from "../EntertrpriseComponents/EnterpriseFeature";
import Reviews from "../../Common Components/Reviews";
import EnterpriseLandingPageCaseStudies from "../EntertrpriseComponents/EnterpriseLandingPageCaseStudies";
import SwitchtoManufacturing from "../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseDividingHeaderPage from "../EntertrpriseComponents/EnterpriseDividingHeaderPage";
import EnterpriseFooter from "../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../Common Components/Whatsapp";
import ContactUsBot from "../../Common Components/ContactUsBot";
import WhatsapPopup from "../../Common Components/WhatsapPopup";
import { Helmet } from 'react-helmet';


const EnterpriseLandingPage = () => {
  let heroData = [
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
  ];

  useEffect(() => {
    setInterval(() => {
      setHeroCount((count) => {
        return count === 2 ? 0 : count + 1;
      });
    }, 60000);
  }, []);
  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(false);

  return (
    <div>
      <Helmet>
        <title>CalidTech Enterprise Solutions - Switches Routers Server Dealer in Mumbai Pune Maharashtra</title>
        <meta name="description" content="Discover comprehensive solutions for Enterprise LAN Wireless, small and medium business LAN and wireless, server and storage solutions, network and cybersecurity, and Ptp and PtMp UBR. Ensure robust, secure, and efficient connectivity and infrastructure for your business with top-tier products and services." />
        <meta name="keywords" content="Lan,Wireless,servers,mimosa,altai" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta name="keywords" content="Juniper Mist Dealer" />
        <meta name="keywords" content="Best Juniper Mist Dealer" />
        <meta name="keywords" content="Juniper Mist Distributor" />

        <meta name="keywords" content="HPE/ARUBA Dealer" />
        <meta name="keywords" content="Best HPE/ARUBA Dealer" />
        <meta name="keywords" content="HPE/ARUBA Distributor" />

        <meta name="keywords" content="Ruckus Dealer" />
        <meta name="keywords" content="Best Ruckus Dealer" />
        <meta name="keywords" content="Ruckus Distributor" />

        <meta name="keywords" content="Cisco Dealer" />
        <meta name="keywords" content="Best Cisco Dealer" />
        <meta name="keywords" content="Cisco Distributor" />
        <meta name="keywords" content="Cisco Firewall Dealer" />
        <meta name="keywords" content="Best Cisco Firewall Dealer" />
        <meta name="keywords" content="Cisco Firewall Distributor" />

        <meta name="keywords" content="Switches Dealer" />
        <meta name="keywords" content="Best Switches Dealer" />
        <meta name="keywords" content="Switches Distributor" />

        <meta name="keywords" content="Wireless Access Point Dealer" />
        <meta name="keywords" content="Best Wireless Access Point Dealer" />
        <meta name="keywords" content="Wireless Access Point Distributor" />

        <meta name="keywords" content="HPE Server Dealer" />
        <meta name="keywords" content="Best HPE Server Dealer" />
        <meta name="keywords" content="HPE Server Distributor" />

        <meta name="keywords" content="Dell Servers Dealer" />
        <meta name="keywords" content="Best Dell Servers Dealer" />
        <meta name="keywords" content="Dell Servers Distributor" />
        <meta name="keywords" content="Dell Storage Dealer" />
        <meta name="keywords" content="Best Dell Storage Dealer" />
        <meta name="keywords" content="Dell Storage Distributor" />

        <meta name="keywords" content="Lenovo Server Dealer" />
        <meta name="keywords" content="Best Lenovo Server Dealer" />
        <meta name="keywords" content="Lenovo Server Distributor" />

        <meta name="keywords" content="Palo Alto Dealer" />
        <meta name="keywords" content="Best Palo Alto Dealer" />
        <meta name="keywords" content="Palo Alto Distributor" />
        <meta name="keywords" content="Palo Alto Firewall Dealer" />
        <meta name="keywords" content="Best Palo Alto Firewall Dealer" />
        <meta name="keywords" content="Palo Alto Firewall Distributor" />

        <meta name="keywords" content="FortiGate Dealer" />
        <meta name="keywords" content="Best FortiGate Dealer" />
        <meta name="keywords" content="FortiGate Distributor" />
        <meta name="keywords" content="FortiGate Firewall Dealer" />
        <meta name="keywords" content="Best FortiGate Firewall Dealer" />
        <meta name="keywords" content="FortiGate Firewall Distributor" />

        <meta name="keywords" content="Sophos Firewall Dealer" />
        <meta name="keywords" content="Best Sophos Firewall Dealer" />
        <meta name="keywords" content="Sophos Firewall Distributor" />

        <meta name="keywords" content="AAA Hotspot Captive Portal Server Dealer" />
        <meta name="keywords" content="Best AAA Hotspot Captive Portal Server Dealer" />
        <meta name="keywords" content="AAA Hotspot Captive Portal Server Distributor" />

        <meta name="keywords" content="Cambium Network Products Dealer" />
        <meta name="keywords" content="Best Cambium Network Products Dealer" />
        <meta name="keywords" content="Cambium Network Products Distributor" />

        <meta name="keywords" content="Radwin Products Dealer" />
        <meta name="keywords" content="Best Radwin Products Dealer" />
        <meta name="keywords" content="Radwin Products Distributor" />

        <meta name="keywords" content="Mimosa Networks Products Dealer" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer" />
        <meta name="keywords" content="Mimosa Networks Products Distributor" />

        <meta name="keywords" content="Altai Products Dealer" />
        <meta name="keywords" content="Best Altai Products Dealer" />
        <meta name="keywords" content="Altai Products Distributor" />

        <meta name="keywords" content="Helmholz Products Dealer" />
        <meta name="keywords" content="Best Helmholz Products Dealer" />
        <meta name="keywords" content="Helmholz Products Distributor" />

        <meta name="keywords" content="Anybus Dealer" />
        <meta name="keywords" content="Best Anybus Dealer" />
        <meta name="keywords" content="Anybus Distributor" />

        <meta name="keywords" content="Moxa Dealer" />
        <meta name="keywords" content="Best Moxa Dealer" />
        <meta name="keywords" content="Moxa Distributor" />

        <meta name="keywords" content="Industrial Switches and Routers Dealer" />
        <meta name="keywords" content="Best Industrial Switches and Routers Dealer" />
        <meta name="keywords" content="Industrial Switches and Routers Distributor" />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer" />
        <meta name="keywords" content="Best Smart IoT 4G Gateway Dealer" />
        <meta name="keywords" content="Smart IoT 4G Gateway Distributor" />

        <meta name="keywords" content="Industrial WiFi Dealer" />
        <meta name="keywords" content="Best Industrial WiFi Dealer" />
        <meta name="keywords" content="Industrial WiFi Distributor" />

        <meta name="keywords" content="Smart Energy Meters Dealer" />
        <meta name="keywords" content="Best Smart Energy Meters Dealer" />
        <meta name="keywords" content="Smart Energy Meters Distributor" />

        <meta name="keywords" content="Dalnex Dealer" />
        <meta name="keywords" content="Best Dalnex Dealer" />
        <meta name="keywords" content="Dalnex Distributor" />

        <meta name="keywords" content="Teltonika Dealer" />
        <meta name="keywords" content="Best Teltonika Dealer" />
        <meta name="keywords" content="Teltonika Distributor" />

        <meta name="keywords" content="Zscaler Dealer" />
        <meta name="keywords" content="Best Zscaler Dealer" />
        <meta name="keywords" content="Zscaler Distributor" />

        <meta name="keywords" content="Blackbear Gateway Dealer" />
        <meta name="keywords" content="Best Blackbear Gateway Dealer" />
        <meta name="keywords" content="Blackbear Gateway Distributor" />


        <meta property="og:type" content="website" />
        <meta property="og:title" content="CalidTech-Enterprise Home" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CalidTech-Enterprise Home" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="CalidTech-Enterprise Home" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseLandingPage" />
      </Helmet>
      <WhatsapPopup />
      <EnterpriseBackground playStatus={playStatus} heroCount={heroCount} />
      <EnterpriseHero
        setPlayStatus={setPlayStatus}
        heroData={heroData}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
      />
      <EnterpriseFeature />
      <EnterpriseLandingPageCaseStudies />
      <Reviews />
      <EnterpriseDividingHeaderPage />
      <Whatsapp />
      <EnterpriseFooter />
      <SwitchtoManufacturing />
      <ContactUsBot />
    </div>
  );
};
export default EnterpriseLandingPage;
