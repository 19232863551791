// EnterpriseSophosSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseSophosSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import Sophos from "../../../assets/pdf/Sophos.pdf";
import { Helmet } from 'react-helmet';


const EnterpriseSophosSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //XGS136
    {
      images: [
        require("../../../assets/productImages/XGS136-1.png"),
        require("../../../assets/productImages/XGS136-2.png"),
        require("../../../assets/productImages/XGS136-1.png"),
        require("../../../assets/productImages/XGS136-2.png"),
      ],
      title: "Sophos XGS136",
      about:(
        <>
        <p>The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Sophos XGS136 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Sophos XGS136.</p>
       
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Firewall thoughput: 11,500 Mbps.</li>
          <li>TLS Inspection thoughput : 950 Mbps.</li>
          <li>IPS thoughput: 4,000 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS2100
    {
      images: [
        require("../../../assets/productImages/XGS2100-1.png"),
        require("../../../assets/productImages/XGS2100-2.png"),
        require("../../../assets/productImages/XGS2100-1.png"),
        require("../../../assets/productImages/XGS2100-2.png"),
      ],
      title: "Sophos XGS2100",
      about:(
        <>
        <p>The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Sophos XGS2100 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Sophos XGS2100.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Firewall throughput: 30,000 Mbps.</li>
          <li>TLS Inspection: 1,100 Mbps.</li>
          <li>IPsec VPN Throughput : 17,000 Mbps</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS2300
    {
      images: [
        require("../../../assets/productImages/XGS2300-1.png"),
        require("../../../assets/productImages/XGS2300-2.png"),
        require("../../../assets/productImages/XGS2300-1.png"),
        require("../../../assets/productImages/XGS2300-2.png"),
      ],
      title: "Sophos XGS2300",
      about:(
        <>
        <p>The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Sophos XGS2300 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Sophos XGS2300.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Firewall throughput: 39,000 Mbps.</li>
          <li>TLS Inspection throughput : 1450 Mbps.</li>
          <li>IPS throughput: 7000 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },

    //XGS3100
    {
      images: [
        require("../../../assets/productImages/XGS3100-1.png"),
        require("../../../assets/productImages/XGS3100-2.png"),
        require("../../../assets/productImages/XGS3100-1.png"),
        require("../../../assets/productImages/XGS3100-2.png"),
      ],
      title: "Sophos XGS3100",
      about:(
        <>
        <p>The Sophos Firewall Xstream architecture is engineered to deliver high levels of visibility, protection, and performance to address some of the greatest challenges facing network administrators today.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Sophos XGS3100 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Sophos XGS3100.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Firewall thoughput: 47,000 Mbps.</li>
          <li>TLS Inspection thoughput: 2470 Mbps.</li>
          <li>IPS thoughput: 10,500 Mbps.</li>
        </>
      ),
      downloadLink: Sophos,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="sophos-enterprise-page">
      <Helmet>
        <title>Sophos Firewall - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Sophos Firewall Dealer in Pune" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Pune" />
        <meta name="keywords" content="Sophos Firewall Distributor in Pune" />

        <meta name="keywords" content="Sophos Firewall Dealer in Mumbai" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Mumbai" />
        <meta name="keywords" content="Sophos Firewall Distributor in Mumbai" />

        <meta name="keywords" content="Sophos Firewall Dealer in Maharashtra" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Maharashtra" />
        <meta name="keywords" content="Sophos Firewall Distributor in Maharashtra" />

        <meta name="keywords" content="Sophos Firewall Dealer in Vadodara" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Vadodara" />
        <meta name="keywords" content="Sophos Firewall Distributor in Vadodara" />

        <meta name="keywords" content="Sophos Firewall Dealer in Surat" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Surat" />
        <meta name="keywords" content="Sophos Firewall Distributor in Surat" />

        <meta name="keywords" content="Sophos Firewall Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Ahmedabad" />
        <meta name="keywords" content="Sophos Firewall Distributor in Ahmedabad" />

        <meta name="keywords" content="Sophos Firewall Dealer in Indore" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Indore" />
        <meta name="keywords" content="Sophos Firewall Distributor in Indore" />

        <meta name="keywords" content="Sophos Firewall Dealer in Jaipur" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Jaipur" />
        <meta name="keywords" content="Sophos Firewall Distributor in Jaipur" />

        <meta name="keywords" content="Sophos Firewall Dealer in Noida" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Noida" />
        <meta name="keywords" content="Sophos Firewall Distributor in Noida" />

        <meta name="keywords" content="Sophos Firewall Dealer in Gurugram" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Gurugram" />
        <meta name="keywords" content="Sophos Firewall Distributor in Gurugram" />

        <meta name="keywords" content="Sophos Firewall Dealer in Delhi" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Delhi" />
        <meta name="keywords" content="Sophos Firewall Distributor in Delhi" />

        <meta name="keywords" content="Sophos Firewall Dealer in Kolkata" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Kolkata" />
        <meta name="keywords" content="Sophos Firewall Distributor in Kolkata" />

        <meta name="keywords" content="Sophos Firewall Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Bhubaneswar" />
        <meta name="keywords" content="Sophos Firewall Distributor in Bhubaneswar" />

        <meta name="keywords" content="Sophos Firewall Dealer in Hyderabad" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Hyderabad" />
        <meta name="keywords" content="Sophos Firewall Distributor in Hyderabad" />

        <meta name="keywords" content="Sophos Firewall Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Visakhapatnam" />
        <meta name="keywords" content="Sophos Firewall Distributor in Visakhapatnam" />

        <meta name="keywords" content="Sophos Firewall Dealer in Chennai" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Chennai" />
        <meta name="keywords" content="Sophos Firewall Distributor in Chennai" />

        <meta name="keywords" content="Sophos Firewall Dealer in Coimbatore" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Coimbatore" />
        <meta name="keywords" content="Sophos Firewall Distributor in Coimbatore" />

        <meta name="keywords" content="Sophos Firewall Dealer in Bengaluru" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Bengaluru" />
        <meta name="keywords" content="Sophos Firewall Distributor in Bengaluru" />

        <meta name="keywords" content="Sophos Firewall Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Tamil Nadu" />
        <meta name="keywords" content="Sophos Firewall Distributor in Tamil Nadu" />

        <meta name="keywords" content="Sophos Firewall Dealer in Karnataka" />
        <meta name="keywords" content="Best Sophos Firewall Dealer in Karnataka" />
        <meta name="keywords" content="Sophos Firewall Distributor in Karnataka" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseSophosSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Network and Cybersecurity - Sophos
      </div>
      <div className="sophos-enterprise-subproduct-nav">
        <button
          className={`sophos-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-sophos-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="sophos-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="sophos-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-sophos-enterprise" : ""
                  }
                >
                  <button
                    className="sophos-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseSophosSubcategoryPage;
