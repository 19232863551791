//ManufacturingDevOpsSubservicePage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingDevOpsSubservicePage.css";
import ManufacturingServicePageNavBar from "../ManufacturingServicePageNavBar";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Connectivity from "../../../assets/images/Connectivity.jpg";
import Interoperability from "../../../assets/images/Interoperability.jpg";
import DataAnalytics from "../../../assets/images/Data-Analytics.jpg";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";
import FactoryDigitalTransformationIOT from "../../../assets/pdf/FactoryDigitalTransformation_IIoT_CalidTech.pptx";

function ManufacturingDevOpsSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  return (
    <div className="manufacturing-service-page">
      <Helmet>
        <title>CalidTech - FDT DevOps</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com/ManufacturingDevOpsSubservicePage" />
      </Helmet>
      <ManufacturingHeader />
      <ManufacturingServicePageNavBar />
      <header className="subservice-header-iot">
        <div className="subheader-text">
          <h1 className="subservice-header-heading">
            <span id="subservice-header-subheading"> Transformative Internet of Things (IoT) Solutions for Businesses Across India and Major Cities</span>
          </h1>
          <p className="subservice-header-description">
            IoT, or the Internet of Things, refers to the network of
            interconnected devices that communicate and exchange data over the
            internet without requiring direct human intervention.
          </p>
          <div className="button-container">
            <a
              href={FactoryDigitalTransformationIOT}
              download="FactoryDigitalTransformation_IIoT_CalidTech.pptx"
              className="Downloadpdf"
            >
              Get PDF
            </a>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="manufacturing-servive-des">
        <h2 className="main-heading-entire-site">
          Smart Water Management, Smart Gas Management, Smart City Solutions
        </h2>
        <p>
        Leveraging IoT technology to efficiently monitor and manage water resources, our smart sensors and real-time data analytics help in detecting leaks, optimizing water usage, and ensuring sustainable water distribution. We implement IoT solutions to monitor gas consumption, detect leaks, and ensure safe and efficient gas distribution. Utilizing IoT technology to create interconnected urban environments, we enhance living conditions through smart systems such as smart lighting, traffic management, waste management, and public safety systems. These IoT-based solutions are designed to improve operational efficiency and sustainability across Indian cities like Mumbai, Delhi, Bangalore, Hyderabad, Chennai, Kolkata, Pune, and Ahmedabad.
        </p>
      </div>

      <div className="subservice-card-div-container">
        <div className="subservice-card-div" style={{ "--clr": "#009688" }}>
          <div className="subservice-card-img-box">
            <img src={Connectivity} alt="Connectivity" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Connectivity</h2>
            <p className="subservice-card-div-description">
              Establishes seamless communication between diverse devices,
              enabling them to interact and share data across networks,
              fostering a cohesive IoT ecosystem.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#FF3E7F" }}>
          <div className="subservice-card-img-box">
            <img src={Interoperability} alt="Interoperability" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Interoperability</h2>
            <p className="subservice-card-div-description">
              Ensures compatibility and smooth operation among heterogeneous
              devices and systems, allowing for standardized communication
              protocols and seamless integration across various platforms.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#03A9F4" }}>
          <div className="subservice-card-img-box">
            <img src={DataAnalytics} alt="Data Analytics" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Data Analytics</h2>
            <p className="subservice-card-div-description">
              Involves the collection, processing, and analysis of vast amounts
              of data generated by IoT devices, extracting valuable insights to
              inform decision-making, optimize operations, and drive innovation.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <Whatsapp />
      <ContactUsBot />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
}

export default ManufacturingDevOpsSubservicePage;
